<i18n>
{
  "en": {
    "title": "Records",
    "contacts": "Contacts",
    "contacts-description": "List of individuals",
    "companies": "Companies",
    "companies-description": "List of legal entity",
    "task-types": "Task Types",
    "task-types-description": "List of recurring activities",
    "products": "Products",
    "products-description": "List of products or services",
    "bills": "Bills",
    "bills-description": "List of payable or receivable bills",
    "users": "Users",
    "users-description": "List of system users"
  },
  "pt-BR": {
    "title": "Registros",
    "contacts": "Contatos",
    "contacts-description": "Lista de pessoa física",
    "companies": "Empresas",
    "companies-description": "Lista de pessoa jurídica",
    "task-types": "Tipos de Tarefas",
    "task-types-description": "Lista de atividades recorrentes",
    "products": "Produtos",
    "products-description": "Lista de produtos ou serviços",
    "bills": "Financeiro",
    "bills-description": "Lista de contas à pagar ou receber",
    "users": "Usuários",
    "users-description": "Lista de usuários do sistema"
  }
}
</i18n>

<template>
  <div id="records">
    <nav-top :title="$t('title')"> </nav-top>
    <board-config>
      <template #config-content>
        <div class="config-grid">
          <we-config-item
            v-for="item in filteredConfigItems"
            :key="item.routeName"
            :item="item"
          />
        </div>
      </template>
    </board-config>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configItems: [
        {
          routeName: "contacts",
          icon: "users",
          name: this.$t("contacts"),
          description: this.$t("contacts-description")
        },
        {
          routeName: "companies",
          icon: "building",
          name: this.$t("companies"),
          description: this.$t("companies-description")
        },
        {
          routeName: "tasksConfigs",
          icon: "tasks",
          name: this.$t("task-types"),
          description: this.$t("task-types-description"),
          rolesEnabled: ["admin", "manager"]
        },
        {
          routeName: "productsConfigs",
          icon: "cubes",
          name: this.$t("products"),
          description: this.$t("products-description"),
          rolesEnabled: ["admin", "manager"]
        },
        {
          routeName: "bills",
          icon: "file-invoice-dollar",
          name: this.$t("bills"),
          description: this.$t("bills-description"),
          rolesEnabled: ["admin"]
        },
        {
          routeName: "users",
          icon: "user-tie",
          name: this.$t("users"),
          description: this.$t("users-description"),
          rolesEnabled: ["admin", "manager"]
        }
      ]
    };
  },
  computed: {
    filteredConfigItems() {
      return this.configItems.filter(e => {
        if (e.rolesEnabled && !e.rolesEnabled.includes(this.loggedUser.role))
          return false;

        return true;
      });
    },
    loggedUser() {
      return this.$store.getters?.getLoggedUser;
    }
  }
};
</script>

<style lang="scss">
#records {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
